<template>
  <v-card
    outlined
    class="d-flex flex-column"
  >
    <v-card-title>
      <h5>Spalten anpassen</h5>
      <v-spacer />
      <v-btn
        icon
        @click="hideColumnSettings"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-list class="dialog-content-overflow">
      <draggable
        v-model="tempColumnSettings"
        handle=".js-draggable"
      >
        <transition-group>
          <v-list-item
            v-for="col in tempColumnSettings"
            :key="col.value"
            :ripple="false"
            class="white"
            @click.stop="toggleColumnSetting(col.value)"
          >
            <v-list-item-action>
              <v-checkbox
                :input-value="col.visible"
                @click.stop="toggleColumnSetting(col.value)"
              />
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>
                {{ col.text }}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-icon class="js-draggable lead-list__draggable">
              <v-icon>mdi-drag-horizontal-variant</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </transition-group>
      </draggable>
    </v-list>

    <v-alert
      v-if="noColumnsSelectedError"
      type="error"
      dense
      tile
      text
    >
      Bitte mindestens eine Spalte auswählen!
    </v-alert>
    <v-spacer />

    <v-card-actions class="justify-end d-flex">
      <v-btn
        right
        :disabled="noColumnsSelectedError"
        small
        color="primary"
        class="w-full"
        @click="saveColumnSettings()"
      >
        Speichern
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { isExpertUser } from '@/lib/productTypes'
import { DEFAULT_COLUMN_SETTINGS, BASIC_COLUMN_SETTINGS } from '../configs'

export default {
  components: {
    draggable: () => import(/* webpackChunkName: "import" */ 'vuedraggable')
  },
  props: {
    hasTool: {
      type: Function,
      required: true
    }
  },

  data () {
    return {
      showColumnSettings: false,
      columnSettings: [],
      tempColumnSettings: [],
      noColumnsSelectedError: false
    }
  },

  created () {
    this.columnSettings = this.restoreColumnSettings()
    this.tempColumnSettings = [...this.columnSettings]
    this.$emit('columnSettingsChanged', this.columnSettings)
  },

  methods: {
    hideColumnSettings () {
      this.showColumnSettings = false
      this.tempColumnSettings = [...this.columnSettings]
      this.$emit('close')
    },

    saveColumnSettings () {
      if (this.tempColumnSettings.some(col => col.visible)) {
        this.noColumnsSelectedError = false
        this.showColumnSettings = false
        this.columnSettings = [...this.tempColumnSettings]
        this.persistColumnSettings(this.columnSettings)
        this.$emit('columnSettingsChanged', this.columnSettings)
      } else {
        this.noColumnsSelectedError = true
      }
    },

    isConversion (value) {
      return ['conversionCountByType.VALUATION',
        'conversionCountByType.PLOT_VALUATION',
        'conversionCountByType.RETURN_CALL',
        'conversionCountByType.LIFE_ANNUITY',
        'conversionCountByType.PARTIAL_SALE',
        'conversionCountByType.PROPERTY_REQUEST',
        'conversions.download.postalShipping'].includes(value)
    },

    toggleColumnSetting (value) {
      this.tempColumnSettings = this.tempColumnSettings.map(col => ({
        ...col,
        ...(col.value === value && { visible: !col.visible }),
        ...(col.value === 'conversions' && this.isConversion(value) && { visible: true })
      }))
    },

    persistColumnSettings (cols) {
      localStorage.setItem('leadList.columnSettings', JSON.stringify(cols))
    },

    restoreColumnSettings () {
      let columns = isExpertUser(this.$auth.user) ? DEFAULT_COLUMN_SETTINGS : BASIC_COLUMN_SETTINGS
      // filter column only for tools/lps the user has
      columns = columns.filter(column =>
        column.value.includes('conversionCountByType') ? !!this.hasTool(column.value.split('.')[1]) : true
      )

      try {
        let cols = localStorage.getItem('leadList.columnSettings')
        cols = JSON.parse(cols)

        const isDifferent = columns.filter(({ value: header1 }) => !cols.some(({ value: header2 }) => header1 === header2))

        if (cols.length !== columns.length || isDifferent.length > 0) {
          return columns
        }

        if (Array.isArray(cols) && cols.some(col => col.visible)) {
          return cols
        }
        throw new Error()
      } catch (err) {
        return columns
      }
    }
  }
}
</script>

<style>
.lead-list__draggable {
  cursor: move;
}
</style>
<style scoped>
.dialog-content-overflow{
  max-height: 100%;
  overflow: scroll
}
</style>
